import Electrical from '../assets/electrical.jpg';
import AC from '../assets/ac.jpg';
import Oxygen from '../assets/oxygen.jpg';
import Plumbing  from '../assets/plumbing.jpg';
import Furniture  from '../assets/furniture.jpg';
import General  from '../assets/general.jpg';
import Other  from '../assets/other.jpg';


const MaintenanceData = [
    {
        image: Electrical,
        title: "Electrical",
    },
    {
        image: AC,
        title: "AC",
    },
    {
        image: Oxygen,
        title: "Oxygen",
    },
    {
        image: Plumbing,
        title: "Plumbing",
    },
    {
        image: Furniture,
        title: "Furniture / Carpentry",
    },
    {
        image: General,
        title: "General",
    },
    {
        image: Other,
        title: "Other",
    },
    {
        image: Other,
        title: "Other",
    },
   
];

export default MaintenanceData;