import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Chip, CircularProgress, FormControl, Icon, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography, useTheme } from '@mui/material';




export default function ImageDialog({ dialogOpen, setDialogOpen, imageFile }) {
  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose}  >
        <img src={imageFile} alt='imageFile'  height='500px'/>
      </Dialog>
    </Box>
  );
}