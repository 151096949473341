import { useState } from "react";
import { styled, Typography, Box, Button } from "@mui/material";
import CustomNavbar from "../components/navbar/Navbar";
import CleaningImg from '../assets/cleaning.jpg'
import RequestImg from '../assets/requests.jpg'
import OtherImg from '../assets/other.jpg'
import Card from "../components/Cards/Card";
import MaintenanceData from "../data/MaintenanceData";
import { useNavigate } from "react-router-dom";


const Wrapper = styled('div')(() => ({
  minHeight: "100vh",
  background: "#E9EBFF",
  padding: "100px 0 50px 0"
}))

const CleaningRow = styled('div')(() => ({
  display: 'flex',
  flexWrap:"wrap",
  width: "80%",
  margin: "50px auto",
  alignItems: "center",
  justifyContent: "space-evenly",
  "@media screen and (max-width:600px)": {
    flexDirection: "column",
    alignItems: "space-evenly"
  },

}))

export default function Maintenance() {
  const navigate=useNavigate();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedCategories([...selectedCategories, value]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== value)
      );
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(selectedCategories);
    navigate(`/services?categories=${selectedCategories.join(",")}`);
  };
  return <>
    <CustomNavbar />
    <form onSubmit={handleSubmit}>
      <Wrapper>
        <Typography align="center" variant='h2'>How may we assist you ?</Typography>
        <CleaningRow>
          {MaintenanceData.map((item, index) => {
            return <Card key={index} title={item.title} image={item.image} handleCheckboxChange={handleCheckboxChange} />
          })}
        </CleaningRow>
        <Box sx={{ width: "100%", display: "flex", margin: "20px 0" }}>
          <Button
            type="submit"
            variant="contained"
            color="success"
            sx={{ margin: "0 auto" }}
          >
            Submit
          </Button>
        </Box>
      </Wrapper>
    </form>
  </>
}
