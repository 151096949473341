import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";

import illustration from "../../assets/illustrations/illustration_avatar.png";

function createData(image, name, Specialization, Qualification, Comments) {
  return { image, name, Specialization, Qualification, Comments };
}
const DoctorContainer = styled("div")(() => ({marginBottom:"50px"}));

const StyledTable = styled(Table)(() => ({
  maxWidth: "80vw",
  margin: "0 auto",
  boxShadow:"none"
}));

const StyledImg = styled("img")(() => ({
  marginBottom: 10,
}));
const rows = [
  createData(
    illustration,
    "Dr. Nitish J S",
    "Cardiology",
    "MBBS",
    "Lorem Ipsum Dolor et solor"
  ),
  createData(
    illustration,
    "Dr. Nitish J S",
    "Cardiology",
    "MBBS",
    "Lorem Ipsum Dolor et solor"
  ),
  createData(
    illustration,
    "Dr. Nitish J S",
    "Cardiology",
    "MBBS",
    "Lorem Ipsum Dolor et solor"
  ),
  createData(
    illustration,
    "Dr. Nitish J S",
    "Cardiology",
    "MBBS",
    "Lorem Ipsum Dolor et solor"
  ),
  createData(
    illustration,
    "Dr. Nitish J S",
    "Cardiology",
    "MBBS",
    "Lorem Ipsum Dolor et solor"
  ),
];

export default function Doctors({doctors}) {
  return (
    <DoctorContainer id='doctors'>
      <Typography
        variant="h4"
        align="center"
        sx={{ width: "80vw", margin: "50px auto" }}
      >
        Doctors
      </Typography>
      <TableContainer component={Paper}>
        <StyledTable size="small">
          <TableHead style={{ backgroundColor: "#57C192" }} >
            <TableRow>
              {["Doctor", "Specialization"].map(
                (head,ind) => (
                  <TableCell
                    style={{
                      color: "black",
                      fontWeight: "700",
                    }}
                    key={ind}
                  >
                    {head}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {doctors.map((row,ind) => (
              <TableRow  key={ind}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ display: "flex", gap: 15 }}
                 
                >
                 
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>{row.split('-')[0]}</span>
                  </div>
                </TableCell>
                <TableCell >{row.split('-')[1]}</TableCell>
                
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </DoctorContainer>
  );
}
