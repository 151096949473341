import { styled } from "@mui/material";
import { HiLocationMarker } from "react-icons/hi";
import { FaEnvelope } from "react-icons/fa";
import { BsPhone } from "react-icons/bs";
import { Box } from "@mui/material";

const ContactWrapper = styled("div")(() => ({
  paddingTop: "50px",
  background: "#E9EBFF",
}));
const ContactContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  margin: "10px 0",
}));

const ContactRow = styled("div")(() => ({
  width: " 60%",
  margin: "auto",
  "@media screen and (max-width: 600px)": {
    width: "100%",
  },
}));

const MapImage = styled("div")(() => ({
  textAlign: "center",
}));
const StyledIframe = styled("iframe")(() => ({
  width: "400px",
  height: "300px",
  "@media screen and (max-width: 600px)": {
    height: "250px",
    width: "200px",
  },
}));
const ContactIconContainer = styled("div")(() => ({
  margin: "10px",
}));

export default function Footer() {
  return (
    <ContactWrapper id="reachus">
      <Box>
        {/* <h1 align="center" style={{ fontSize: "2.5rem" }}>
          Reach Us
        </h1> */}
        <ContactRow className="row mt-1">
          <div className="col-lg-8">
            <ContactContainer>
              <ContactIconContainer>
                <HiLocationMarker />
              </ContactIconContainer>
              <div className="contact-text-container">
                <h4>Location</h4>
                <p>
                  <a href="#">
                    One teller
                  </a>
                </p>
              </div>
            </ContactContainer>
            <ContactContainer>
              <ContactIconContainer>
                <BsPhone />
              </ContactIconContainer>
              <div className="contact-text-container">
                <h4>Phone</h4>
                <p>
                  7010365232
                </p>
              </div>
            </ContactContainer>
            <ContactContainer>
              <ContactIconContainer>
                <FaEnvelope />
              </ContactIconContainer>
              <div className="contact-text-container">
                <h4>Website</h4>
                <p>
                  <a href="highonswift.com">
                    highonswift.com
                  </a>
                </p>
              </div>
            </ContactContainer>
          </div>
          <MapImage className="col-lg-4 mt-lg-0 mb-5 map-image">
            <StyledIframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.4078182050266!2d80.23673459848979!3d12.94573572686079!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d9b1e142efb%3A0x9c85f180cd65656e!2sHighonspaces%20-%20Highonswift!5e0!3m2!1sen!2sin!4v1682564164645!5m2!1sen!2sin"
              style={{ border: 0 }}
            ></StyledIframe>
          </MapImage>
        </ContactRow>
      </Box>
    </ContactWrapper>
  );
}
