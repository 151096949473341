import { styled } from "@mui/material";

const TextBox = styled("div")(() => ({
  margin: "20px 0",
  display: "flex",
  justifyContent: "center",
}));

export default function FeedbackTextbox(props) {
  const { comment, setComment } = props;
  return (
    <TextBox>
      <textarea
        rows={4}
        cols={30}
        placeholder="Enter your comments here"
        style={{ padding: "5px" }}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        // onChange={(e) => handleFeedbackChange(e)}
      />
    </TextBox>
  );
}
