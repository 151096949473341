import React from "react";
import { styled } from "@mui/material/styles";
import Doctor from "../../assets/doctor.svg";
import { Button, Typography, Box } from "@mui/material";
import img from "../../assets/illustrations/4990224-removebg.png";
import { Navigate, useNavigate } from "react-router-dom";

const Container = styled("div")(() => ({
 
  backgroundColor: "#E9EBFF",
  "@media screen and (max-width:1100px)": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Content = styled("div")(() => ({
  zIndex: 1,
 
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media screen and (max-width:1100px)": {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

const LeftContent = styled("div")(() => ({
  height: "100%",
  flex: 1,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  "@media screen and (max-width:600px)": {
    alignItems: "start",
  },
}));
const LeftText = styled("div")(() => ({
  width: "60%",
  margin: "0 auto",
  fontWeight: "bolder",
  fontFamily: "monserrat",
  textAlign: "left",
  "@media screen and (max-width:1000px)": {
    width: "80%",
    fontSize: "1rem",
  },
}));
const RightContent = styled("div")(() => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const Image = styled("img")(() => ({
  height: "500px",
  "@media screen and (max-width:750px)": {
    height: "250px",
  },
}));

export default function HospitalLanding({hospitalName}) {
  const navigate = useNavigate()
  return (
    // <>HospitalLanding</>
    <Container>
      <Content>
        <LeftContent>
          <LeftText>
            <Typography variant="h5">Welcome to {hospitalName}</Typography>
            <Typography variant="h6">Your Trusted Healthcare Partner</Typography>

            {/* <Box sx={{ display: "flex" }}>
              <Button
                size="large"
                variant="contained"
                sx={{ margin: "20px 10px 20px 0", backgroundColor: "#57C192" }}
                onClick={() => navigate("/hospital-information")}
              >
                Contact US
              </Button>
              <Button
                size="large"
                variant="outlined"
                sx={{
                  margin: "20px 0 20px 10px",
                  color: "#57C192",
                }}
              >
                Learn More
              </Button>
            </Box> */}
          </LeftText>
        </LeftContent>
        <RightContent>
          <Image src={img} />
        </RightContent>
      </Content>
    </Container>
  );
}
