import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Stack, Typography } from '@mui/material';

export default function AppFooter() {
  const [value, setValue] = React.useState(0);

  return (
    <Box sx={{ backgroundColor:'#F3F6F9' }}>
       
      <Stack 
       sx={{ display:'flex', alignItems:'center' }}
      >
       <Typography sx={{ fontWeight: 600, fontSize: '15px', m:1}}>Powered by TELLERCOMM</Typography>
       <Typography sx={{ fontWeight: 600, fontSize: '12px' }}> © Tellerrcomm 2023</Typography>

      </Stack>
    </Box>
  );
}
