import React, { createContext, useEffect, useState } from "react";

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const QrContext = createContext(undefined);
const QrDispatchContext = createContext(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function QrProvider({ children }) {
  const [qrDetails, setQrDetails] = useState(JSON.parse(localStorage.getItem('qrDetails')));
  const [powerUsers, setPowerUsers] = useState(JSON.parse(localStorage.getItem('powerUsers')));
  const [feedbackSection, setFeedbackSection] = useState(JSON.parse(localStorage.getItem('feedbackSection')));
  // console.log('in context');
  useEffect(() => {
    localStorage.setItem('qrDetails', JSON.stringify(qrDetails))
    localStorage.setItem('powerUsers', JSON.stringify(powerUsers))
    localStorage.setItem('feedbackSection', JSON.stringify(feedbackSection))
  },[qrDetails])
  return (
    <QrContext.Provider value={{qrDetails, powerUsers, feedbackSection}}>
      <QrDispatchContext.Provider value={{setQrDetails, setPowerUsers, setFeedbackSection}}>
        {children}
      </QrDispatchContext.Provider>
    </QrContext.Provider>
  );
}

export { QrProvider, QrContext, QrDispatchContext };