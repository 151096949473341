// First we need to import axios.js
import axios, { AxiosInstance } from "axios";

// import { getUserAccessToken } from "utils/storage";

// import firebase from "firebase";
import { initializeApp } from "firebase/app";
import {getAuth, RecaptchaVerifier} from 'firebase/auth'
export const createApiConfig = () => {
    // Next we make an 'instance' of it
    //   const token = getUserAccessToken();

    const instance = axios.create({
        // .. where we make our configurations
        baseURL: process.env.REACT_APP_BASE_URL,
    });
    // Where you would set stuff like your 'Authorization' header, etc ...
    //   if (token) {
    //     instance.defaults.headers.common["Authorization"] = `Token ${token}`;
    //   }
    // instance.interceptors.response.use(({ data }) => data);
    // Also add/ configure interceptors && all the other cool stuff
    return instance;
};

const firebaseConfig = {
    apiKey: "AIzaSyBgGSvPuveOl2bra5nkshn4Is1M0fFhs6A",
    authDomain: "teller-one.firebaseapp.com",
    projectId: "teller-one",
    storageBucket: "teller-one.appspot.com",
    messagingSenderId: "834077419839",
    appId: "1:834077419839:web:de0c5b486b38bcb16b8b8a"
};
const firbaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firbaseApp);

