//mui
import { Box, Typography, styled } from "@mui/material";

//react icons
import { BsEmojiLaughing } from "react-icons/bs";
import { BsEmojiSmile } from "react-icons/bs";
import { BsEmojiNeutral } from "react-icons/bs";
import { BsEmojiFrown } from "react-icons/bs";
import { BsEmojiAngry } from "react-icons/bs";

const Question = styled("div")(() => ({
  width: "80%",
  margin: "0 auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "@media screen and (max-width:600px)": {
    flexDirection: "column",
  },
}));

const ReviewIconRow = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
}));
const IconBox = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  margin: "15px",
  alignItems: "center",
}));

const StyledTextArea = styled('textarea')(() => ({
  margin: "20px 0",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#f7f7f7",
  borderRadius: "5px",
  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
  "& textarea": {
    border: "none",
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    resize: "none",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#333",
    "&::placeholder": {
      color: "#999",
      opacity: 1,
    },
    "&:focus": {
      outline: "none",
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
    },
  },
}))

export default function FeedbackQuestion(props) {
  const { question, handleFeedbackChange } = props;
  return (
    <Box sx={{ padding: { xs: "5px", md: "10px" } }}>
      <Typography align="left" sx={{ marginLeft: { md: "10%" } }} >
        {question.question}
      </Typography>
      <Question>
        <ReviewIconRow>
          <IconBox>
            <BsEmojiLaughing color="darkgreen" size="2rem" />
            <Typography
              variant="subtitle2"
              align="center"
              sx={{ color: "darkgreen" }}
            >
              Excellent
            </Typography>
            <input
              type="radio"
              id={`question${question.id}-excellent`}
              name={`question${question.id}`}
              data-question-id={question.id}
              data-feedback-type="excellent"
              value="excellent"
              onChange={handleFeedbackChange}

            />
          </IconBox>
          <IconBox>
            <BsEmojiSmile color="green" size="2rem" />
            <Typography
              variant="subtitle2"
              align="center"
              sx={{ color: "green" }}
            >
              Good
            </Typography>
            <input
              type="radio"
              id={`question${question.id}-veryGood`}
              name={`question${question.id}`}
              data-question-id={question.id}
              data-feedback-type="veryGood"
              value="veryGood"
              onChange={handleFeedbackChange}
            />
          </IconBox>
          <IconBox>
            <BsEmojiNeutral color="orange" size="2rem" />
            <Typography
              variant="subtitle2"
              align="center"
              sx={{ color: "orange" }}
            >
              Medium
            </Typography>
            <input
              type="radio"
              id={`question${question.id}-medium`}
              name={`question${question.id}`}
              data-question-id={question.id}
              data-feedback-type="medium"
              onChange={handleFeedbackChange}
              value="medium"
            />
          </IconBox>
          <IconBox>
            <BsEmojiFrown color="red" size="2rem" />
            <Typography variant="subtitle2" align="center" sx={{ color: "red" }}>
              Poor
            </Typography>
            <input
              type="radio"
              id={`question${question.id}-poor`}
              name={`question${question.id}`}
              data-question-id={question.id}
              data-feedback-type="poor"
              onChange={handleFeedbackChange}
              value="poor"
            />
          </IconBox>
          <IconBox>
            <BsEmojiAngry color="#ba1c1c" size="2rem" />
            <Typography variant="subtitle2" align="center" sx={{ color: "#ba1c1c	" }} >
              Very Bad
            </Typography>
            <input
              type="radio"
              id={`question${question.id}-veryBad`}
              name={`question${question.id}`}
              data-question-id={question.id}
              data-feedback-type="veryBad"
              value="veryBad"
              onChange={handleFeedbackChange}
              size='fixed'
            />
          </IconBox>
        </ReviewIconRow>

        <StyledTextArea
          rows={4}
          cols={30}
          placeholder="Enter your comments here"
          style={{ padding: "5px" }}
          id={`question${question.id}-comments`}
          name={`question${question.id}-comments`}
          data-question-id={question.id}
          data-feedback-type="comments"
          value={question.feedback.comments}
          onChange={handleFeedbackChange}
        />
      </Question>
    </Box>
  );
}
