import { useState, useEffect } from "react";

import Router from "./routes";

import SplashScreen from "./components/splash-screen/SplashScreen";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  if (loading) {
    return <SplashScreen />;
  }
  return (
    <>
      <Router />
    </>
  );
}

export default App;
