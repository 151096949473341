import { Box, Grid, Typography, StyledRating, Number, Rating, TextField, Button, useMediaQuery } from "@mui/material";
import * as React from 'react';
import logo from '../../assets/logo.png'
import { blue, blueGrey } from "@mui/material/colors";
import feedback from '../../assets/feedback.png';
import { styled } from '@mui/material/styles';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getFeedbackQuestion, postFeedbackQuestion } from "../../api/auth";
// import { postFeedbackQuestion } from "../../api/auth";
import { QrContext } from "../../provider";
import CustomSnackBar from "../snackbar";
import { useState } from "react";
import { useContext } from "react";
import ResponsiveAppBar from "../Appbar";
import AppFooter from "../BottomNavigation";
//Rating reactions
import PropTypes from 'prop-types';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';


const FeedbackQuestions = () => {

  const { state } = useLocation();
  const { qrDetails } = useContext(QrContext);
  const navigate = useNavigate();
  const [checkedSection, setCheckedSection] = useState(state.selectedSection.filter((ele) => ele.checked === true))
  const [feedbackquestions, setFeedbackQuestions] = useState(null)
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const textFieldStyles = {
    width: '400px', // Adjust width as needed
    // height: '40px', // Adjust height as needed
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)
  const [inputs, setInputs] = useState({suggestion : ''})
  const [response, setResponse] = useState(null)
  // console.log((qrDetails.hospitalId));

  const fetchData = async () => {
    // console.log(state);
    try {

      const { data } = await getFeedbackQuestion(state.id)

      if (data.success) {
        // console.log(data.feedbackQuestions.questions.filter((fb) => {return checkedSection.some((item) => {return fb.sectionTypeId === item.sectionTypeId})} ));
        setFeedbackQuestions(data.feedbackQuestions.questions.filter((fb) => { return checkedSection.some((item) => { return fb.sectionTypeId === item.sectionTypeId }) }))
        setResponse(data.feedbackQuestions.questions.filter((fb) => { return checkedSection.some((item) => { return fb.sectionTypeId === item.sectionTypeId }) }).map((item) => ({ ...item, rating: '', comments: '', })))
      }
      else {
        setSnackbarMessage({ msg: "Request failed", success: false });
        setSnackbarOpen(true);
      }

    }
    catch (error) {
      // setIsLoading(false)
      console.log("err ", error);
    }
  }

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled
    },
  }));

  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" fontSize="10px"/>,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" fontSize="10px"/>,
      label: 'Dissatisfied',
    },
    3: {
      icon: <SentimentSatisfiedIcon color="warning" fontSize="10px"/>,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="success" fontSize="10px"/>,
      label: 'Satisfied',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon color="success" fontSize="10px"/>,
      label: 'Very Satisfied',
    },
  };

  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
  };


  const handleChange = (e, id) => {
     console.log(response, feedbackquestions, id, e.target.name, e.target.value);
    const tempInputs = [...response];
    const inp = tempInputs.find(
      a => a.questionId === id
    );
    if (e.target.name === 'rating') {
      inp.rating = e.target.value;
    }
    if (e.target.name === 'comments') {
      // console.log('sds');
      inp.comments = e.target.value;
    }
    setResponse(tempInputs);
    // event.preventDefault();
  }

  const handleInputChange = (e) => {
    // console.log(e.target.name, e.target.value);
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  }
  // console.log(inputs);

  const handleClick = (event) => {
    event.preventDefault();
    const fbresponse = {
      hospitalId: qrDetails.hospitalId,
      responses: response,
      suggestion: inputs.suggestion
    }

    navigate('/authentication', { state: { fbresponse, from: 'feedback' } })
    console.log(fbresponse);

    // console.log("as", inputs, response);
    // try{
    //   const { data } = await postFeedbackQuestion({...inputs, hospitalId:qrDetails.hospitalId, responses:response})
    //   if (data.success)
    //   {
    //     console.log({data});
    //   }
    //   else {
    //     setSnackbarMessage({ msg: "Request failed", success: false });
    //     setSnackbarOpen(true);
    //   }

    // }
    // catch (error) {
    //   // setIsLoading(false)
    //   console.log("err ", error);
    // }

  }


  useEffect(() => {
    fetchData()
  }, [])


  return (

    <Box  sx={{ flexGrow: 1 }}>
      <ResponsiveAppBar />
     
      {matches ? (
        <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
          <Box mt={1} mb={1}>
            <img src={feedback} alt="logo" height='100px' width='150px' />
          </Box>

          <Typography sx={{ fontWeight: 600, color: '#6d6b6dde', }} variant="h5" component="h4" >GIVE YOUR FEEDBACK</Typography>
          <Typography sx={{ wordWrap: 'break-word' }}>Welcome to our feedback page. If you would like to send us your views, rate our services and click 'Submit'.</Typography>

        </Box>
      ) : (
        <Box display='flex' justifyContent='center' item xs>
          <Box mt={1} mb={1} mr={3}>
            <img src={feedback} alt="logo" height='100px' width='150px' />
          </Box>
          <Box borderRadius={3} mt={1} mb={1}>
            <Typography mt={2} sx={{ fontWeight: 600, color: '#6d6b6dde' }} variant="h4" component="h4" display='flex' justifyContent='center' alignItems={'center'}>GIVE YOUR FEEDBACK</Typography>
            <Typography display='flex' justifyContent='center'>Welcome to our feedback page. If you would like to send us your views, rate our services and click 'Submit'.</Typography>
          </Box>
        </Box>
      )}

      {feedbackquestions?.map((item,ind) =>
        <>
          {matches ? (
            <Grid sx={{ backgroundColor: 'aliceblue' }} mt={1} mb={1} alignItems={"center"}>
              <Typography sx={{ fontWeight: 600 }} marginLeft={1} marginRight={1} mb={3} mt={3}>{item.question}</Typography>
              <StyledRating
                name="rating"
                
                value={response[ind].rating}
                size='large'
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly
                onChange={(e) => handleChange(e, item.questionId)}

              />
              <TextField sx={{ margin: 1 }} size="small" id="outlined-multiline-static" name="comments" onChange={(e) => handleChange(e, item.questionId)} />
            </Grid>

          ) : (
            <Grid sx={{ backgroundColor: 'aliceblue', marginLeft: 10, marginRight: 10 }} display='flex' justifyContent='space-between' mt={1} mb={1} alignItems={"center"}>
              <Typography width='800px' margin={2} mb={3} mt={3}>{item.question}</Typography>
              <StyledRating
                name="rating"
                
                size='large'
                value={response[ind].rating}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly
                onChange={(e) => handleChange(e, item.questionId)}
              />
              <TextField sx={{ margin: 2 }} size="small" id="outlined-multiline-static" name="comments" onChange={(e) => handleChange(e, item.questionId)} />
            </Grid>
          )}
        </>
      )}

      {matches ? (
        <Grid sx={{ backgroundColor: 'aliceblue' }} mt={1} mb={1} alignItems={"center"} display={"flex"} justifyContent='center' >
          <Box>
            <Typography marginLeft={1} marginRight={1} mb={2} sx={{ fontWeight: 600, backgroundColor: 'darkcyan' }}  >What are the things you feel we should improve upon, and do you have any other recommendations or concerns?</Typography>
            <TextField name="suggestions" onChange={handleInputChange} sx={{ margin: 1 }} size="small" id="outlined-multiline-static" />
          </Box>
        </Grid>
      ) : (

        <Box sx={{ backgroundColor: 'aliceblue' }} display={"flex"} justifyContent='center' >
          <Box container rowSpacing={{ xl: 1 }} columnSpacing={{ xl: 20 }} justifyContent='center'>
            <Typography marginLeft={1} marginRight={1} mb={3} mt={3}>What are the things you feel we should improve upon, and do you have any other recommendations or concerns?</Typography>
            <Box display="flex" justifyContent="center">
              <TextField name="suggestions" onChange={handleInputChange} inputProps={{ style: textFieldStyles }} id="outlined-multiline-static" />
            </Box>
          </Box>
        </Box>
      )}

      <Grid padding={'30px'} display='flex' justifyContent="space-evenly" >
        <Button variant="contained" onClick={handleClick} >Submit</Button>
      </Grid>

      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
<AppFooter />
    </Box>
  );
}

export default FeedbackQuestions;