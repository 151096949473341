import { styled, Box, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import FeedbackTextbox from "../components/FeedbackTextbox/FeedbackTextbox";
import CustomNavbar from "../components/navbar/Navbar";
import ServicesChecklist from "../components/ServicesChecklist/ServicesChecklist";
import Services from "../data/Services";

const Wrapper = styled("div")(() => ({
  paddingTop: "100px",
  paddingBottom: "25px",
  background: "#E9EBFF",
  minHeight: "100vh",
}));
const CardWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
}));
export default function ServicesPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categories = searchParams.get("categories") || "";
  const categoryList = categories.split(",");
  // console.log(categoryList);
  const [comment, setComment] = useState();
  const handleSubmit = (event) => {
    event.preventDefault();
    const selectedServices = services.filter((service) => service.selected);
    console.log(selectedServices, comment);
    navigate(`/requests/thankyou`);
  };
  const [services, setServices] = useState(() => {
    if (categoryList[0] === "") {
      return Services.filter((serv) => serv.type === "General");
    } else {
      return Services.filter((serv) => categoryList.includes(serv.type));
    }
  });

  const servicesByType = services.reduce((acc, curr) => {
    if (!acc[curr.type]) {
      acc[curr.type] = [];
    }
    acc[curr.type].push(curr);
    return acc;
  }, {});

  return (
    <>
      <CustomNavbar />
      <form onSubmit={handleSubmit}>
        <Wrapper>
          <CardWrapper>
            {Object.entries(servicesByType).map(([type, typeServices]) => (
              <ServicesChecklist
                key={type}
                type={type}
                title={typeServices.title}
                services={typeServices}
                onCheckboxChange={(id, selected) =>
                  setServices((prevServices) =>
                    prevServices.map((service) =>
                      service.id === id ? { ...service, selected } : service
                    )
                  )
                }
              />
            ))}
          </CardWrapper>
          <FeedbackTextbox
            comment={comment}
            setComment={setComment}
          ></FeedbackTextbox>
          <Box sx={{ width: "100%", display: "flex", margin: "20px 0" }}>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{ margin: "0 auto" }}
            >
              Submit
            </Button>
          </Box>
        </Wrapper>
      </form>
    </>
  );
}
