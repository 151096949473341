import { Typography } from "@mui/material";
import { styled } from "@mui/material";
import Logo from "../../assets/logo.png";

const AboutUsWrapper = styled("div")(() => ({
  fontSize: "2.5rem",
  margin:"50px 0"
}));
const AboutContainer = styled("div")(() => ({
  margin: "20px 0",
  display: "flex",
  "@media screen and (max-width:1100px)": {
    flexDirection: "column",
  },
}));
const AboutImgContainer = styled("div")(() => ({
  flex: 1,
  textAlign: "center",
  padding: "5%",
}));

const AboutText = styled("div")(() => ({
  fontFamily: "montserrat",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
  display: "flex",
  padding: " 0% 10%",
  textAlign: "justify",
  "@media screen and (max-width:600px)": {
    padding: "5%",
  },
}));

export default function AboutHospital({aboutUs}) {
  return (
    <AboutUsWrapper id="aboutus">
      <Typography variant="h4" align="center">
        About Us
      </Typography>
      <AboutContainer>
       
        <AboutText>
          <Typography variant="subtitle1">
           {aboutUs}
          </Typography>
        </AboutText>
      </AboutContainer>
    </AboutUsWrapper>
  );
}
