import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//mui
import { styled } from "@mui/material/styles";
import { Typography, Box, Button } from "@mui/material";

//icons
import Doctor from '../../assets/doctor.png'
import Nurse from '../../assets/nurse.png'
import Lab from '../../assets/lab.png'
import Pharmacy from '../../assets/pharmacy.png'
import Canteen from '../../assets/canteen.png'
import Billing from '../../assets/billing.png'
import HouseKeeping from '../../assets/housekeeping.png'
import maintenance from '../../assets/maintenance.png'
import other from '../../assets/other.jpg'
import CustomNavbar from "../navbar/Navbar";

const Wrapper = styled("div")(() => ({
  width: "100%",
  margin: "0 auto",
  minHeight: "100vh",
  backgroundColor: "#E9EBFF",
}));

const FormWrapper = styled("div")(() => ({
  width: "80%",
  margin: "0 auto",
}));

const Row = styled("div")(() => ({
  display: "flex",
  margin: "50px 0",
}));

const InputBox = styled("div")(() => ({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
}));

const IconBox = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

const StyledImage = styled('img')(() => ({
  height: "85px",
  width: "85px",
  margin: "0 5px"
}))

function FeedbackLanding() {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedCategories([...selectedCategories, value]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== value)
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`/results?categories=${selectedCategories.join(",")}`);
  };

  return (
    <Wrapper>
      <CustomNavbar />
      <Typography variant="h6" align="center" paddingTop={20}>
        Which areas of hospital you want to give feedback on?
      </Typography>
      <FormWrapper>
        <form onSubmit={handleSubmit}>
          <Row>
            <InputBox>
              <IconBox>
                <StyledImage src={Doctor} />
                <Typography variant="subtitle2" align="center">
                  Doctor
                </Typography>
              </IconBox>
              <input
                type="checkbox"
                value="Doctor"
                onChange={handleCheckboxChange}
              />
            </InputBox>
            <InputBox>
              <IconBox>
                <StyledImage src={Nurse} />
                <Typography variant="subtitle2" align="center">
                  Nursing Staff
                </Typography>
              </IconBox>
              <input
                type="checkbox"
                value="Nursing Staff"
                onChange={handleCheckboxChange}
              />
            </InputBox>
            <InputBox>
              <IconBox>
                <StyledImage src={Lab} />
                <Typography variant="subtitle2" align="center">
                  Laboratories
                </Typography>
              </IconBox>
              <input
                type="checkbox"
                value="Laboratories"
                onChange={handleCheckboxChange}
              />
            </InputBox>
          </Row>
          <Row>
            <InputBox>
              <IconBox>
                <StyledImage src={Billing} />
                <Typography variant="subtitle2" align="center">
                  Billing
                </Typography>
              </IconBox>
              <input
                type="checkbox"
                value="Billing"
                onChange={handleCheckboxChange}
              />
            </InputBox>
            <InputBox>
              <IconBox>
                <StyledImage src={Pharmacy} />
                <Typography variant="subtitle2" align="center">
                  Pharmacy
                </Typography>
              </IconBox>
              <input
                type="checkbox"
                value="Pharmacy"
                onChange={handleCheckboxChange}
              />
            </InputBox>
            <InputBox>
              <IconBox>
                <StyledImage src={Canteen} />
                <Typography variant="subtitle2" align="center">
                  Canteen
                </Typography>
              </IconBox>
              <input
                type="checkbox"
                value="Canteen"
                onChange={handleCheckboxChange}
              />
            </InputBox>
          </Row>
          <Row>
            <InputBox>
              <IconBox>
                <StyledImage src={HouseKeeping} />
                <Typography variant="subtitle2" align="center">
                  Housekeeping
                </Typography>
              </IconBox>
              <input
                type="checkbox"
                value="Housekeeping"
                onChange={handleCheckboxChange}
              />
            </InputBox>
            <InputBox>
              <IconBox>
                <StyledImage src={maintenance} />
                <Typography variant="subtitle2" align="center">
                  Maintenance
                </Typography>
              </IconBox>
              <input
                type="checkbox"
                value="Maintenance"
                onChange={handleCheckboxChange}
              />
            </InputBox>
            {/* <InputBox>
              <IconBox>
                <StyledImage src={other} />
                <Typography variant="subtitle2" align="center">
                  Others
                </Typography>
              </IconBox>
              <input
                type="checkbox"
                value="Others"
                onChange={handleCheckboxChange}
              />
            </InputBox> */}
          </Row>
          <Box sx={{ width: "100%", display: "flex", margin: "20px 0" }}>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{ margin: "0 auto" }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </FormWrapper>
    </Wrapper>
  );
}

export default FeedbackLanding;
