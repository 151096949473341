import { Navigate, useRoutes } from "react-router-dom";

import SimpleLayout from "./layout/simple/SimpleLayout";
import Page404 from "./pages/Page404";
import Landing from "./pages/Landing";
import Hospitalinfo from "./pages/Hospitalinfo";
import Maintenance from "./pages/Maintenance";
import Feedback from "./pages/Feedback";
import FeedbackResults from "./components/FeedbackPage/FeedbackPage";
import ThankYou from "./pages/Thankyou";
import ThankYouRequest from "./pages/ThankyouRequest";
import Services from "./pages/Services";
import HousekeepingAndMaintenance from "./pages/HouseKeepingAndMaintenance";
import Authentication from "./pages/Authentication";
import FeedbackNew from "./pages/Feedback_New";
import FeedbackQuestion from "./components/FeedbackQuestion/FeedbackQuestion";
import FeedbackQuestions from "./components/FeedbackPage/feedbackquestionnew";

export default function Router() {
  const routes = useRoutes([
    {
      element: <SimpleLayout />,
      children: [
        { path: "404", element: <Page404 /> },

      ],
    },
    {
      // element: <SimpleLayout />,
      path: "/*",
      element: <Landing />
    },
   
    {
      path: "/authentication",
      element: <Authentication />,
    },
    {
      path: "/hospital-information",
      element: <Hospitalinfo />,
    },
    {
      path: "/Maintenance",
      element: <Maintenance />,
    },
    {
      path: "/housekeeping-maintenance",
      element: <HousekeepingAndMaintenance />,
    },
    {
      path: "/feedback",
      element: <Feedback />,
    },
    {
      path: "/feedback_new",
      element: <FeedbackNew />,
    },
    {
      path: "/results",
      element: <FeedbackResults />,
    },
    {
      path: "/feedback/thankyou",
      element: <ThankYou />,
    },
    {
      path: "/requests/thankyou",
      element: <ThankYouRequest />,
    },
    {
      path: "/services",
      element: <Services />,
    },
    {
      path: "/feedbackquestionnew",
      element: <FeedbackQuestions />,
    },
  ]);

  return routes;
}
