import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, styled, Typography } from "@mui/material";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import doctorImage from "../../assets/doctor.svg";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        transform: "scale(1.5)",
        color: "black",
      }}
      onClick={onClick}
    >
      <BsArrowLeftCircleFill />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", transform: "scale(1.5)" }}
      onClick={onClick}
    />
  );
}

const CarouselContainer = styled("div")(() => ({
  margin: "50px auto",
  maxWidth: "80vw",
}));

const CarouselImage = styled("img")(() => ({
  borderRadius: "50%",
  height: "200px",
  width: "200px",
  textAlign: "center",
  position: "relative",
  left: "25%",
  margin: "20px 0",
}));
const Card = styled("div")(() => ({
  border: "none",
}));

export default function Carousel({ specialities }) {
  const settings = {
    dots: true,
    infinite: true,
    // speed: 500000,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow className="slick-arrow" />,
    prevArrow: <SamplePrevArrow className="slick-arrow" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <CarouselContainer id="services">
      <Typography variant="h4" align="center" sx={{ marginBottom: "20px" }}>
      Specialities
      </Typography>
      <Slider {...settings}>
        {specialities.map((item, index) => {
          return (
            <Card id={index} className="card" key={index}>
              <Box>
                <CarouselImage
                  src={doctorImage}
                  className="card-img"
                  alt='alt'
                />
              </Box>
              <Typography variant="h5" align="center">
                {item}
              </Typography>
            </Card>
          );
        })}
      </Slider>
    </CarouselContainer>
  );
}
