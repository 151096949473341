import { useEffect, useState } from 'react';

// mui -------------------------
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, CircularProgress, Typography } from "@mui/material";

// assets
import logo from '../assets/logo.svg'
import hosInfo from '../assets/hi.jpg'
import hkMain from '../assets/hk_main.jpg'
import feedback from '../assets/fb.png'

// icons--------------------------------
import { FaRegHospital } from "react-icons/fa";
import { RiTodoLine, RiFeedbackLine } from "react-icons/ri";
import { GiBroom } from "react-icons/gi";
//router dom
import { Link, useNavigate } from "react-router-dom";
import CustomSnackBar from '../components/snackbar';
import { useContext } from 'react';
import { QrDispatchContext } from '../provider';
import { getHospitalDetails } from '../api/auth';
import ResponsiveAppBar from '../components/Appbar';
import AppFooter from '../components/BottomNavigation';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const data = [
  {

    title: "Hospital Information",
    img: hosInfo,
    link: '/hospital-information',
    id: '64c322e5e93121a63938dfff'
  },
  {

    title: "Housekeeping & Maintenance",
    img: hkMain,
    link: '/housekeeping-maintenance',
    id: '6478933e7af47dd211527a24'
  },

  {

    title: "Feedback",
    img: feedback,
    link: '/feedback_new',
    id: '6478933e7af47dd211527a23'
  },

];

export default function Landing() {

  const navigate = useNavigate();
  const path = window.location.pathname.split('/')[1]
  const [qrId, setQrId] = useState(null);
  const { setQrDetails, setPowerUsers, setFeedbackSection } = useContext(QrDispatchContext);


 localStorage.setItem('qrId', path)

  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  const getLocationString = async () => {
    console.log(path);
    try {
      const { data } = await getHospitalDetails(path)
      if (data.success) {
        // setIsLoading(false)
        if (data.result.output.status !== 'active') {
          setSnackbarMessage({ msg: "QR is not active please contact admin", success: false });
          setSnackbarOpen(true);
          return
        }
        setQrDetails(data.result.output)
        setPowerUsers(data.result.powerUsers)
        setQrId(data.result.list)
        setFeedbackSection(data.result.feedbackSectionTypes)
        console.log('heer',data.result.list.modules.filter(element => { return element.licenseStatus === 'active' }));
      }
      else {
        setSnackbarMessage({ msg: "Request failed", success: false });
        setSnackbarOpen(true);
      }

    }
    catch (error) {
      // setIsLoading(false)
      console.log("err ", error);
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }

  }

  useEffect(() => {
     getLocationString();
  }, [])

  const handleNavigate = (link) => {
    navigate(link)
  }
  // sx={{backgroundColor:'#454'}}
  return (
    <Box sx={{ width: '100vw', height: '100vh' }}>
      {qrId &&<ResponsiveAppBar />}
      
      <Grid display='flex' alignItems='center' flexDirection='column' height={{sm:'76%'}} >
         <Grid container  display='flex' justifyContent='center' alignItems='center' rowSpacing={2} mt={4}>
          {/* {qrId ? data.filter(item => qrId.modules.filter(element => { return element.licenseStatus === 'active' }).map((item) => item._id).includes(item.id)).map((dataItem) => (
            <Grid item xs={12} sm={6} md={4} key={dataItem.id}>
              <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' onClick={() => handleNavigate(dataItem.link)}>
                <Box display='flex' justifyContent='center' alignItems='center' sx={{ border: '2px solid #993365', borderRadius: 8, maxWidth: '250px', maxHeight: '200px', p: 2, cursor: 'pointer' }}><img src={dataItem.img} alt="hi" width='90%' height='90%' /></Box>
                <Typography align='center' sx={{ fontWeight: 600, fontSize: '20px', m: 2 }}>{dataItem.title}</Typography>
              </Box>
            </Grid>
          )) : <CircularProgress />} */}
          {qrId ? qrId.modules.map((dataItem) => (
            dataItem.licenseStatus === 'active' &&
            (<Grid item xs={12} sm={6} md={4} key={dataItem.moduleId}>
              {dataItem.moduleName === 'hospitalinformation' &&
              <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' onClick={() => handleNavigate('/hospital-information')}>
                <Box display='flex' justifyContent='center' alignItems='center' sx={{ border: '2px solid #993365', borderRadius: 8, maxWidth: '250px', maxHeight: '200px', p: 2, cursor: 'pointer' }}><img src={hosInfo} alt="hi" width='90%' height='90%' /></Box>
                 <Typography align='center' sx={{ fontWeight: 600, fontSize: '20px', m: 2 }}>Hospital Information</Typography>
              </Box>}
              {(dataItem.moduleName === 'housekeeping') &&
              <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' onClick={() => handleNavigate( '/housekeeping-maintenance')}>
                <Box display='flex' justifyContent='center' alignItems='center' sx={{ border: '2px solid #993365', borderRadius: 8, maxWidth: '250px', maxHeight: '200px', p: 2, cursor: 'pointer' }}><img src={hkMain} alt="hi" width='90%' height='90%' /></Box>
                 <Typography align='center' sx={{ fontWeight: 600, fontSize: '20px', m: 2 }}>Housekeeping & Maintenance</Typography>
              </Box>}
              {dataItem.moduleName === 'feedback' &&
              <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' onClick={() => handleNavigate('/feedback_new')}>
                <Box display='flex' justifyContent='center' alignItems='center' sx={{ border: '2px solid #993365', borderRadius: 8, maxWidth: '250px', maxHeight: '200px', p: 2, cursor: 'pointer' }}><img src={feedback} alt="hi" width='90%' height='90%' /></Box>
                 <Typography align='center' sx={{ fontWeight: 600, fontSize: '20px', m: 2 }}>Feedback</Typography>
              </Box>}
            </Grid>)
          )) : <CircularProgress />}
        </Grid>
      </Grid>
      <AppFooter />
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
    </Box>
  );
}
