// Material UI
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
// Loader
import { DotLoader } from "react-spinners";
// Logo
import Logo from "../../assets/logo.png";

const SplashScreenContainer = styled("div")(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFF",
}));
const SplashLogo = styled("div")(() => ({
  maxWidth: "200px",
  minWidth: "200px",
}));
const SplashTitle = styled("div")(() => ({
  marginTop: "20px",
  fontSize: "24px",
  color: "#333333",
}));

export default function SplashScreen() {
  return (
    <SplashScreenContainer>
      <SplashLogo>
        <img src={Logo} alt="Logo" />
      </SplashLogo>
      <SplashTitle>
        <Typography variant="h3">Teller One</Typography>
      </SplashTitle>
      <DotLoader size={100} color="#f82b84" />
    </SplashScreenContainer>
  );
}
