const Questions = [
  {
    id: 1,
    type: "general",
    question:
      "How easy was it to navigate to your destination within our facility?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 2,
    type: "general",
    question:
      "How would you rate your coordination between different departments?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 3,
    type: "general",
    question:
      "How would you rate the overall care provided by our medical personnel?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 4,
    type: "general",
    question:
      "How would you rate the overall care provided by our medical personnel?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 5,
    type: "general",
    question: "Do you feel that our work hours are well suited to treat you?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 6,
    type: "general",
    question:
      "How would you rate the quality of service received at our facility?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 7,
    type: "general",
    question: "Were our answers able to address all your questions?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 8,
    type: "general",
    question:
      "How likely are you to recommend our medical facility to your friends and family members?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 9,
    type: "general",
    question:
      "How would you rate us based on our concern for your privacy and transparency?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 10,
    type: "general",
    question: "How would you rate the overall care provided at our facility?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 11,
    type: "general",
    question: "How would you rate the security staff behaviour?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 12,
    type: "general",
    question: "How would you rate the parking staff behaviour?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 13,
    type: "Doctor",
    question: "How satisfied were you with the doctor?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 13,
    type: "Nursing Staff",
    question: "How satisfied were you with the nursing staff?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 14,
    type: "Laboratory",
    question: "How satisfied were you with the laboratory services?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 15,
    type: "Billing",
    question: "How satisfied were you with the billing process?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 16,
    type: "Pharmacy",
    question: "How satisfied were you with the pharmacy services?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 16,
    type: "Canteen",
    question: "How satisfied were you with the canteen services?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 17,
    type: "Housekeeping",
    question: "How satisfied were you with the housekeeping services?",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },
  {
    id: 18,
    type: "Maintenance",
    question: "How satisfied were you with the maintenance services",
    feedback: {
      excellent: "",
      veryGood: "",
      medium: "",
      poor: "",
      veryBad: "",
      comments: "",
    },
  },

  // Add more questions here
];

export default Questions;
