const Services = [
  {
    id: 1,
    type: "Cleaning",
    title: "Cleaning",
    service: "Bed Cleaning (Linen)",
    selected: false,
  },
  {
    id: 2,
    type: "Cleaning",
    title: "Cleaning",
    service: "Table Cleaning",
    selected: false,
  },
  {
    id: 3,
    type: "Cleaning",
    title: "Cleaning",
    service: "Room Cleaning",
    selected: false,
  },
  {
    id: 4,
    type: "Cleaning",
    title: "Cleaning",
    service: "Bed Cleaning (Linen)",
    selected: false,
  },
  {
    id: 5,
    type: "Cleaning",
    title: "Cleaning",
    service: "Floor Cleaning",
    selected: false,
  },
  {
    id: 6,
    type: "Cleaning",
    title: "Cleaning",
    service: "Toilet Cleaning",
    selected: false,
  },
  {
    id: 7,
    type: "Cleaning",
    title: "Cleaning",
    service: "Commode Cleaning",
    selected: false,
  },
  {
    id: 8,
    type: "Cleaning",
    title: "Cleaning",
    service: "Washbasin Cleaning",
    selected: false,
  },
  {
    id: 9,
    type: "Cleaning",
    title: "Cleaning",
    service: "Patient vomit Cleaning",
    selected: false,
  },
  {
    id: 10,
    type: "Cleaning",
    title: "Cleaning",
    service: "Bed pan Cleaning",
    selected: false,
  },
  {
    id: 11,
    type: "Cleaning",
    title: "Cleaning",
    service: "Urinal bed pan Cleaning",
    selected: false,
  },
  {
    id: 12,
    type: "Requests",
    title: "Requests by Patient/Attender",
    service: " Extra Pillow for patient / attender",
    selected: false,
  },
  {
    id: 13,
    type: "Requests",
    title: "Requests by Patient/Attender",
    service: "Extra Blanket for patient / attender",
    selected: false,
  },
  {
    id: 14,
    type: "Requests",
    title: "Requests by Patient/Attender",
    service: "Bed pan",
    selected: false,
  },
  {
    id: 15,
    type: "Requests",
    title: "Requests by Patient/Attender",
    service: "Towel bath for patient",
    selected: false,
  },
  {
    id: 16,
    type: "Requests",
    title: "Requests by Patient/Attender",
    service: "Dress change for patient",
    selected: false,
  },
  {
    id: 17,
    type: "Requests",
    title: "Requests by Patient/Attender",
    service: "Diaper change for patient",
    selected: false,
  },
  {
    id: 18,
    type: "Requests",
    title: "Requests by Patient/Attender",
    service: "Wheelchair for patient",
    selected: false,
  },
  {
    id: 19,
    type: "Requests",
    title: "Requests by Patient/Attender",
    service: "Newspapers / Magazines",
    selected: false,
  },
  {
    id: 20,
    type: "Requests",
    title: "Requests by Patient/Attender",
    service: "Buy something for patient from outside",
    selected: false,
  },
  {
    id: 21,
    type: "Requests",
    title: "Requests by Patient/Attender",
    service: "Hot water for drinking",
    selected: false,
  },
  {
    id: 22,
    type: "Electrical",
    title: "Electrical",
    service: "No Power Supply",
    selected: false,
  },
  {
    id: 23,
    type: "Electrical",
    title: "Electrical",
    service: "TV not working",
    selected: false,
  },
  {
    id: 24,
    type: "Electrical",
    title: "Electrical",
    service: "TV remote not working",
    selected: false,
  },
  {
    id: 25,
    type: "Electrical",
    title: "Electrical",
    service: "TV remote is missing",
    selected: false,
  },
  {
    id: 26,
    type: "Electrical",
    title: "Electrical",
    service: "Light not working",
    selected: false,
  },
  {
    id: 27,
    type: "Electrical",
    title: "Electrical",
    service: "Fan not working",
    selected: false,
  },
  {
    id: 28,
    type: "Electrical",
    title: "Electrical",
    service: "Geyser not working",
    selected: false,
  },
  {
    id: 29,
    type: "Electrical",
    title: "Electrical",
    service: "Power socket not working",
    selected: false,
  },
  {
    id: 30,
    type: "Electrical",
    title: "Electrical",
    service: "A/C is not working",
    selected: false,
  },
  {
    id: 31,
    type: "Electrical",
    title: "Electrical",
    service: "A/C is too cold",
    selected: false,
  },
  {
    id: 32,
    type: "AC",
    title: "AC",
    service: "A/C is not working",
    selected: false,
  },
  {
    id: 33,
    type: "AC",
    title: "AC",
    service: "A/C is too cold",
    selected: false,
  },
  {
    id: 34,
    type: "AC",
    title: "AC",
    service: "A/C noise is too loud",
    selected: false,
  },
  {
    id: 35,
    type: "AC",
    title: "AC",
    service: "A/C is spraying water",
    selected: false,
  },
  {
    id: 36,
    type: "AC",
    title: "AC",
    service: "A/C water is leaking",
    selected: false,
  },
  {
    id: 37,
    type: "AC",
    title: "AC",
    service: "A/C is smelling foul",
    selected: false,
  },
  {
    id: 38,
    type: "Oxygen",
    title: "Oxygen",
    service: "Oxygen / Air leak",
    selected: false,
  },
  {
    id: 39,
    type: "Plumbing",
    title: "Plumbing",
    service: "Water is leaking from Tap",
    selected: false,
  },
  {
    id: 40,
    type: "Plumbing",
    title: "Plumbing",
    service: "Water is leaking from Geyser",
    selected: false,
  },
  {
    id: 41,
    type: "Plumbing",
    title: "Plumbing",
    service: "Tap is broken",
    selected: false,
  },
  {
    id: 42,
    type: "Plumbing",
    title: "Plumbing",
    service: "No hot water from Geyser",
    selected: false,
  },
  {
    id: 43,
    type: "Plumbing",
    title: "Plumbing",
    service: "No water supply in bathroom",
    selected: false,
  },
  {
    id: 44,
    type: "Furniture/Carpentry",
    title: "Furniture/Carpentry",
    service: "Chair is broken",
    selected: false,
  },
  {
    id: 45,
    type: "Furniture/Carpentry",
    title: "Furniture/Carpentry",
    service: "Nail is coming out of the chair",
    selected: false,
  },
  {
    id: 46,
    type: "Furniture/Carpentry",
    title: "Furniture/Carpentry",
    service: "Cot is very shaky",
    selected: false,
  },
  {
    id: 47,
    type: "Furniture/Carpentry",
    title: "Furniture/Carpentry",
    service: "Mattress is very hard",
    selected: false,
  },
  {
    id: 48,
    type: "Furniture/Carpentry",
    title: "Furniture/Carpentry",
    service: "Door is making screeching noise",
    selected: false,
  },
  {
    id: 49,
    type: "General",
    title: "General",
    service: "Lift is not working",
    selected: false,
  },
  {
    id: 50,
    type: "General",
    title: "General",
    service: "Room is very noisy",
    selected: false,
  },
  {
    id: 51,
    type: "General",
    title: "General",
    service: "Room is very smelly",
    selected: false,
  },

  // Add more questions here
];

export default Services;
