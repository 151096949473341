import { styled, Typography } from '@mui/material'


const CleaningCard = styled("div")(() => ({
    boxSizing: 'border-box',
    width: '300px',
    height: '300px',
    borderRadius: "12px",
    background: "rgb(252, 253, 255)",
    // boxShadow: "10px -10px 20px #dbdce4,-10px 10px 20px #ffffff",
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.5s',
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    fontWeight: 'bolder',
    margin: "20px",
    '&:hover': {
        border: "1px solid #57C192",
        transform: "scale(1.05)",
    },
    "@media screen and (max-width:600px)": {
        margin:"20px 0"
      },
}))

const StyledImage = styled('img')(() => ({
    height: "150px",
    width: "150px",
    margin: "10px 0"
}))


export default function Card({ image, title, handleCheckboxChange }) {
    return (
        <CleaningCard>
            <StyledImage src={image} />
            <Typography variant="h5" sx={{ margin: "10px 0" }}>{title}</Typography>
            <input
                height='50px'
                width='50px'
                type="checkbox"
                value={title}
                onChange={handleCheckboxChange}
            />
        </CleaningCard>
    )
}
