import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { QrContext } from "../provider";
import { useContext } from "react";
import { styled, Typography, Box, Button, Grid, ButtonGroup, List, ListItem, ListItemButton, ListItemText, FormGroup, FormControlLabel, Checkbox, iconButtonClasses, TextField, CircularProgress } from "@mui/material";
import CustomNavbar from "../components/navbar/Navbar";
import CleaningImg from '../assets/cleaning.jpg'
import RequestImg from '../assets/requests.jpg'
import OtherImg from '../assets/other.jpg'
import Card from "../components/Cards/Card";
import HouseKeepingData from "../data/HouseKeepingData";
import { useNavigate } from "react-router-dom";

// assets
import logo from '../assets/logo.svg'
import hosInfo from '../assets/hi.jpg'
import hkMain from '../assets/hk_main.jpg'
import feedback from '../assets/fb.jpg'
import { getServices } from "../api/auth";
import CustomSnackBar from "../components/snackbar";
import ResponsiveAppBar from "../components/Appbar";
import AppFooter from "../components/BottomNavigation";
import { ArrowBackIosNew } from "@mui/icons-material";



const Wrapper = styled('div')(() => ({
  minHeight: "100vh",
  background: "#E9EBFF",
  padding: "100px 0 50px 0"
}))

const CleaningRow = styled('div')(() => ({
  display: 'flex',
  width: "80%",
  margin: "50px auto",
  alignItems: "center",
  justifyContent: "space-evenly",
  "@media screen and (max-width:600px)": {
    flexDirection: "column",
    alignItems: "space-evenly"
  },

}))

const initialItems = [
  {
    id: 1,
    title: 'Bed cleaning (Linen)',
    checked: true
  },
  {
    id: 2,
    title: 'Table cleaning',
    checked: true
  },
  {
    id: 3,
    title: 'Room cleaning',
    checked: true
  },
  {
    id: 4,
    title: 'Floor cleaning',
    checked: true
  },
]

export default function HousekeepingAndMaintenance() {

  const location = useLocation();
  const navigate = useNavigate();
  const { qrDetails, powerUsers } = useContext(QrContext);

  const [items, setItems] = useState(null);
  const [housekeepingServices, setHousekeepingServices] = useState([]);
  const [maintenanceServices, setMaintenanceServices] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [query, setQuery] = useState(null);
  const [selectedServices, setSelectedServices] = useState(null);
  const [housekeepingState, setHousekeepingState] = useState(true);
  const [maintenanceState, setMaintenanceState] = useState(false);
  const [housekeepingServiceState, setHousekeepingServiceState] = useState(false);
  const [maintenanceServiceState, setMaintenanceServiceState] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  console.log(qrDetails, powerUsers);

  const fetchData = async () => {

    try {
      const { data } = await getServices(qrDetails.hospitalId)
      if (data.success) {

        setHousekeepingServices(data.housekeeping.services)
        setMaintenanceServices(data.maintenance.services)

        // console.log(data);

      }
      else {
        setSnackbarMessage({ msg: "Request failed", success: false });
        setSnackbarOpen(true);
      }

    }
    catch (error) {
      // setIsLoading(false)
      console.log("err ", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])


  const handleSubmit = (event) => {
    event.preventDefault();

    const requestedService = {
      hospitalId: qrDetails.hospitalId,
      serviceId: selectedService.serviceId,
      subServices: items,
      query,
      qrId: qrDetails.locationId,
    }
    navigate('/authentication', { state: { requestedService, from:'services' } })
    console.log(requestedService);
  };

  const handleCheck = (id, checked) => {
    console.log('check', id, checked);
    setItems((prevServices) =>
      prevServices.map((service) =>
        service.subServiceId === id ? { ...service, checked } : service
      )
    )
  };


  const handleServices = (st, item) => {
    if (st) {
      setHousekeepingServiceState(true)
      setMaintenanceServiceState(false)
    }
    else {
      setMaintenanceServiceState(true)
      setHousekeepingServiceState(false)
    }
    setItems(item.subServices);
    setSelectedService(item)
    setHousekeepingState(false)
    setMaintenanceState(false)
    setSelectedServices(item)
  };

  const handlePrev = (st) => {
    if (st) {
      setHousekeepingState(true)
      setMaintenanceState(false)
    }
    else {
      setHousekeepingState(false)
      setMaintenanceState(true)
    }
    setMaintenanceServiceState(false)
    setHousekeepingServiceState(false)
  };

  const handleSwitch = (st) => {
    if (st) {
      setHousekeepingState(true)
      setMaintenanceState(false)
      setHousekeepingServiceState(false)
      setMaintenanceServiceState(false)
    }
    else {
      setMaintenanceState(true)
      setHousekeepingState(false)
      setHousekeepingServiceState(false)
      setMaintenanceServiceState(false)
    }
  }
  // sx={{backgroundColor:'#454'}}
  // console.log(HouseKeepingData)
  return (

    <Box sx={{ width: '100vw', height: '100vh' }}>
            <ResponsiveAppBar />

     
      {housekeepingServices.length || maintenanceServices.length ? (
        <Grid container display='flex' justifyContent='center' alignItems='center' mt={2}  >
          <Box display='flex' justifyContent='center' alignItems='center' width='100%' >
            <ButtonGroup aria-label="outlined button group" sx={{ width: '350px' }} >
              <Button variant={(housekeepingState || housekeepingServiceState) ? 'contained' : 'outlined'} onClick={() => handleSwitch(true)}><Typography sx={{ fontWeight: 600, fontSize: '20px', m: 2 }}>Housekeeping</Typography></Button>
              <Button variant={(maintenanceState || maintenanceServiceState) ? 'contained' : 'outlined'} onClick={() => handleSwitch(false)}><Typography sx={{ fontWeight: 600, fontSize: '20px', m: 2 }}>Maintenance</Typography></Button>
            </ButtonGroup>
          </Box>
          {maintenanceState && (

            <Grid container display='flex' justifyContent='center' alignItems='center' rowSpacing={2} mt={4} mb={{sm:7}}>
              {maintenanceServices.map((item, ind) => (
                <Grid item xs={12} sm={6} md={4} key={ind}>
                  <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' onClick={() => handleServices(false, item)}>
                    <Box display='flex' justifyContent='center' alignItems='center' sx={{ border: '2px solid #993365', borderRadius: 8, maxWidth: '250px', maxHeight: '200px', p: 2, cursor: 'pointer' }}><img src={item.icon} alt="icon" width='90%' height='90%' /></Box>
                    <Typography sx={{ fontWeight: 600, fontSize: '20px', m: 2 }}>{item.serviceTitle}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>


          )}
          {maintenanceServiceState && (
            <Grid container  display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
              <Box mt={6} >
              

                <Box display='flex' justifyContent='center' sx={{ backgroundColor: '#B3D2FB', width: '200px', borderRadius: 12 }}>
                <ArrowBackIosNew sx={{m:2, cursor:'pointer'}} onClick = {() => {setMaintenanceServiceState(false); setMaintenanceState(true) }}/>
                  <Typography sx={{ fontWeight: 600, fontSize: '14px', m: 2 }}>
                  {selectedServices.serviceTitle.toUpperCase()}
                  </Typography>
                </Box>
                <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' ml={8}>
                  <List>
                    <FormGroup>
                      {selectedServices.subServices.map((item, ind) => (
                        <ListItem disablePadding key={ind}>
                          <FormControlLabel control={<Checkbox name={item.subServiceTitle}
                            checked={item.subServiceChecked}
                            onChange={(e) => handleCheck(item.subServiceId, e.target.checked)} />} label={item.subServiceTitle} />
                        </ListItem>
                      ))}
                    </FormGroup>
                  </List>
                  <TextField id="outlined-basic" label="Enter unlisted services here" variant="outlined" name="query" onChange={(e) => { setQuery(e.target.value) }} />
                </Box>
                <Box display='flex' justifyContent='center' alignItems='center' my={2}>
                  <Button variant='outlined' fullWidth onClick={() => handlePrev(false)}>Back</Button>
                  <Button variant='outlined' fullWidth onClick={handleSubmit}>Submit</Button>
                </Box>
              </Box>
            </Grid>


          )}
          {housekeepingState && (
            <Grid container  display='flex' justifyContent='center' alignItems='center' rowSpacing={2} mt={4} mb={{xs:10,sm:26}}>
              {housekeepingServices.map((item, ind) => (
                <Grid item xs={12} sm={6} md={4} key={ind}>
                  <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' onClick={() => handleServices(true, item)}>
                    <Box display='flex' justifyContent='center' alignItems='center' sx={{ border: '2px solid #993365', borderRadius: 8, maxWidth: '250px', maxHeight: '200px', p: 2, cursor: 'pointer' }}><img src={item.icon} alt="icon" width='90%' height='90%' /></Box>
                    <Typography sx={{ fontWeight: 600, fontSize: '20px', m: 2 }}>{item.serviceTitle}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
          {housekeepingServiceState && (
            <Grid container  display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
              
              <Box mt={6} >
              

                <Box display='flex'  sx={{ backgroundColor: '#B3D2FB', width: '200px', borderRadius: 12 }}>
                <ArrowBackIosNew sx={{m:2, cursor:'pointer'}} onClick = {() => {setHousekeepingServiceState(false); setHousekeepingState(true) }}/>
                  <Typography sx={{ fontWeight: 600, fontSize: '14px', m: 2 }}>
                    {selectedServices.serviceTitle.toUpperCase()}
                  </Typography>
                </Box>
                <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' ml={8}>
                  <List>
                    <FormGroup>
                      {selectedServices.subServices.map((item, ind) => (
                        <ListItem disablePadding key={ind}>
                          <FormControlLabel control={<Checkbox name={item.subServiceTitle}
                            checked={item.subServiceChecked}
                            onChange={(e) => handleCheck(item.subServiceId, e.target.checked)} />} label={item.subServiceTitle} />
                        </ListItem>
                      ))}
                    </FormGroup>
                  </List>
                  <TextField id="outlined-basic" label="Enter unlisted services here" variant="outlined" name="query" onChange={(e) => { setQuery(e.target.value) }} />
                </Box>

                <Box display='flex' justifyContent='center' alignItems='center' my={2}>
                  <Button variant='outlined' fullWidth onClick={() => handlePrev(true)}>Back</Button>
                  <Button variant='outlined' fullWidth onClick={handleSubmit}>Submit</Button>
                </Box>
              </Box>
            </Grid>
          )}
          
        </Grid>
      ) : (
        <Grid display='flex' justifyContent='center' sx={{ height: '75vh' }}>
          <CircularProgress />
        </Grid>
      )}
      <AppFooter />
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
    </Box>)

  {/* <CustomNavbar />
    <form onSubmit={handleSubmit}>

      <Wrapper>
        <Typography align="center" variant='h2'>How may we assist you ?</Typography>
        <CleaningRow>
          {HouseKeepingData.map((item, index) => {
            return <Card key={index} title={item.title} image={item.image} handleCheckboxChange={handleCheckboxChange} />
          })}
        </CleaningRow>
        <Box sx={{ width: "100%", display: "flex", margin: "20px 0" }}>
          <Button
            type="submit"
            variant="contained"
            color="success"
            sx={{ margin: "0 auto" }}
          >
            Submit
          </Button>
        </Box>
      </Wrapper>
    </form> */}
}
