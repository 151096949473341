import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
//
import App from "./App";

//mui
import { ThemeProvider, createTheme } from "@mui/material";
import { QrProvider } from "./provider";

const theme = createTheme({
  typography: {
    "fontFamily": `"Montserrat", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  }
});

//---------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <BrowserRouter>
          <QrProvider>
            <App />
          </QrProvider>
        </BrowserRouter>
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>
);
