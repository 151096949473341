import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Container,
  Box,
  styled,
  LinearProgress,
} from "@mui/material";
import { BsFillCheckCircleFill } from "react-icons/bs";

const StyledButton = styled(Button)(() => ({
  padding: "10px",
  borderRadius: "10px",
}));
const ButtonText = styled(Typography)(() => ({
  fontSize: "150%",
  letterSpacing: "3px",
}));
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          sx={{ height: "10px", borderRadius: "5px" }}
          color="success"
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const ThankYouRequest = () => {
  return (
    <Container sx={{ width: "90%" }}>
      <LinearProgressWithLabel value={100} />
      <BsFillCheckCircleFill
        style={{ margin: "5% auto", width: "100%", textAlign: "center" }}
        color="green"
        size={"5rem"}
      />

      <Typography
        variant="h3"
        fontFamily="Monument Extended"
        sx={{ textAlign: "center", color: "black" }}
      >
        THANK YOU VERY MUCH!
      </Typography>

      <Typography
        variant="h4"
        fontFamily="Poppins"
        sx={{
          textAlign: "center",
          color: "#009254",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        Your request has been forwarded to the concerned department and will be
        attended to shortly
        <br />
        <br />
        Any further requests, kindly let us know. We will be happy to help
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "5% auto",
        }}
      >
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <StyledButton
            variant="contained"
            sx={{
              backgroundColor: "#22424D",
            }}
          >
            <ButtonText fontFamily="Monument Extended">HOME</ButtonText>
          </StyledButton>
        </Link>
      </Box>
    </Container>
  );
};

export default ThankYouRequest;
