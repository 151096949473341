import { styled, Typography } from "@mui/material"

const Wrapper = styled('div')(() => ({
    display: "flex",
    alignItems: "center",
    width: "80%",
    margin: "20px auto",
    justifyContent: "space-evenly",
    "@media screen and (max-width:600px)": {
        flexDirection: "column",
        width: "100%",
        padding: "20px",
        textAlign: "center"
    },
}))
const DescriptionContainer = styled("div")(() => ({
    width: "40%",
    position: "relative",
    "@media screen and (max-width:600px)": {
        height: "50%",
        width: "100%",
    },
}))
const SmallYellowCircle = styled('div')(() => ({
    backgroundColor: '#57C192',
    borderRadius: '50%',
    height: '60px',
    width: '60px',
    position: 'absolute',
    top: "5%",
    left: "0%",
    zIndex: 10,
}))
const StyledImage = styled('img')(() => ({
    borderRadius: "50%",
    height: "400px",
    width: "400px",
    "@media screen and (max-width:600px)": {
        height: "250px",
        width: "250px",
    },
    "@media screen and (max-width:1100px)": {
        height: "300px",
        width: "300px",
    },
}))
const LargeYellowCircle = styled('div')(() => ({
    backgroundColor: '#57C192',
    borderRadius: '50%',
    height: '100px',
    width: '100px',
    position: 'absolute',
    bottom: "5%",
    right: "0",
    zIndex: -10,
}))
const Description = styled("div")(() => ({}))
const ImageContainer = styled("div")(() => ({
    position: 'relative',
}))
export default function EventsBanner(props) {
    const { title, description, image } = props;
    return (
        <>
            <Wrapper>
                <DescriptionContainer>
                    <Description>
                        <Typography variant="h5" >{title}</Typography>
                        <Typography align="left" sx={{ fontSize: "1.2rem", marginTop: "20px" }}>
                            {description}
                        </Typography>
                    </Description>
                </DescriptionContainer>
                <ImageContainer>
                    <SmallYellowCircle></SmallYellowCircle>
                    <LargeYellowCircle></LargeYellowCircle>
                    <StyledImage src={image} />
                </ImageContainer>
            </Wrapper>
        </>
    )
}
