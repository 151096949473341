import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import logo from '../assets/logo.svg'
import doctor from '../assets/doctor.png';
import nurse from '../assets/nurse.png';
import lab from '../assets/lab.png';
import billing from '../assets/billing.png';
import pharmacy from '../assets/pharmacy.png';
import canteen from '../assets/canteen.png';
import housekeeping from '../assets/housekeeping.png';
import maintenance from '../assets/maintenance.png';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { QrContext } from "../provider";
import { dark } from '@mui/material/styles/createPalette';
import { useContext } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomSnackBar from '../components/snackbar';
import ResponsiveAppBar from '../components/Appbar';
import AppFooter from '../components/BottomNavigation';


const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  // padding: theme,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function FeedbackNew() {

  const navigate = useNavigate()
  const { feedbackSection, qrDetails } = useContext(QrContext);
  const [selectedSection, setSelectedSection] = useState(feedbackSection.map((item) => ({...item, checked:false})))
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)
  console.log(qrDetails);
  const handleChange = (flag, id) => {
    console.log('abc', id, flag);
    setSelectedSection(selectedSection.map((ss) => {
      if(ss.sectionTypeId === id)
      {
        return {...ss, checked: flag}
      }
      return {...ss}
    }));
    
  };
  const handleSubmit = () => {
    console.log(selectedSection);
    // if(selectedSection.length)
    // {
    //   setSnackbarMessage({ msg: "Choose atleast one section", success: false });
    //   setSnackbarOpen(true);
    //   return;
    // }
    navigate('/feedbackquestionnew', {state:{selectedSection, id:qrDetails.hospitalId}})
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
            <ResponsiveAppBar />

     
      <Box pt={2}>
        <Typography sx={{ fontWeight: 600, color: '#6d6b6dde' }} variant="h4" component="h4" display='flex' justifyContent='center' alignItems={'center'}>FEEDBACK</Typography>
      </Box>
      <Grid px={12} pt={2} height='100%' container rowSpacing={{ xl: 1 }} columnSpacing={{ xl: 20 }}>
        {
          feedbackSection?.map((item, index) =>
            <Grid item xs>
              <Item sx={{cursor:'pointer'}} onClick={() => handleChange(!selectedSection[index].checked,item.sectionTypeId)}>
                <img src={doctor} alt='logo' height='120px' width='200px' />
                <Box display='flex' justifyContent="space-evenly">
                  <FormControlLabel control={<Checkbox checked={selectedSection[index].checked} onChange={(e) => handleChange(e.target.checked, item.sectionTypeId)} />} label={item.sectionTypeName.charAt(0).toUpperCase() + item.sectionTypeName.slice(1)} />
                </Box>
              </Item>
            </Grid>
          )
        }
      </Grid>
      <Grid padding={'30px'} display='flex' justifyContent="space-evenly" >
        <Button variant="contained" onClick={handleSubmit}>Submit</Button>
      </Grid>
      <AppFooter />

      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />

    </Box>
  );
}