import axios from 'axios'
import { useEffect, useState } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import OtpInput from 'react-otp-input';
import OTPInput, { ResendOTP } from "otp-input-react";

// mui -------------------------
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Stack, TextField, Typography, Container } from "@mui/material";

// assets
import logo from '../assets/logo.svg'
import subTic from '../assets/subTic.png'
import hkMain from '../assets/hk_main.jpg'
import feedback from '../assets/fb.png'

// api
import { firebaseAuth } from "../api/config";


// icons--------------------------------
import { FaRegHospital } from "react-icons/fa";
import { RiTodoLine, RiFeedbackLine } from "react-icons/ri";
import { GiBroom } from "react-icons/gi";
//router dom
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext } from 'react';
import { QrContext } from '../provider';
import { addFeedback, addServices, postContact } from '../api/auth';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import ResponsiveAppBar from '../components/Appbar';
import AppFooter from '../components/BottomNavigation';
import CustomSnackBar from '../components/snackbar';

export default function Authentication() {
  const { qrDetails } = useContext(QrContext);

  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location.state);
  const [inputs, setInputs] = useState({name: 'govarthan', phone: 8610428340});
  const [viewOtpForm, setViewOtpForm] = useState(false)
  const [result, setResult] = useState(null);
  const [otp, setOtp] = useState('');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)


  const handleClose = () => {
    setOpen(false);
    navigate(`/${qrDetails.locationId}`)
  };

  
  const handleCallback = () => {
    navigate(`/${qrDetails.locationId}`)
  }
  // const handlesummon = 
  //   localStorage.setItem('button', event.target.id)
  //   setOpen(false);
  //   navigate(`/${qrDetails.locationId}`)
    
  
  // const handleYes = () => {
  //   //setOpen(false);
  //   //navigate(`/${qrDetails.locationId}`)
  //   console.log('Yes');
  // };
  // const handleNo = () => {
  //   //setOpen(false);
  //   //navigate(`/${qrDetails.locationId}`)
  //   console.log('No');
  // };

  const handleChange = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true)
    if (inputs.phone === "" || inputs.phone.length < 10) return;
    // console.log('sdsd', inputs);

    const captchaVerifier = new RecaptchaVerifier(
      "recaptcha-container", {
      size: "invisible",
      callback: function (response) {
        console.log("Captcha Resolved");
      },
      defaultCountry: "IN",
    }, firebaseAuth);

    await signInWithPhoneNumber(firebaseAuth, `+91${inputs.phone}`, captchaVerifier)
      .then((result) => {
        setSnackbarMessage({ msg: "Otp sent successfully", success: true });
        setSnackbarOpen(true);
        setViewOtpForm(true);
        setResult(result)
        console.log(result);
        setIsLoading(false)
      })
      .catch((err) => {
        setSnackbarMessage({ msg: err.message, success: false });
        setSnackbarOpen(true);
        console.log(err);
        setIsLoading(false)
      });
  };

  const handleOtp = async (e) => {
    const services = { ...location.state.requestedService, mobileNo: inputs.phone, patientName: inputs.name }
    e.preventDefault()
    setIsLoading(true)
    console.log(services)

    const feedbackResponse = {
      ...location.state.fbresponse,
      mobileNo: inputs.phone
    }

    console.log(feedbackResponse);

    await result.confirm(otp)
      .then(async (res) => {
        console.log(res, services);
        
        try {
          if (location.state.from === 'hosinfo') {
            console.log('in');
            
            const { data } = await postContact(location.state.requestedService, qrDetails.hospitalId)

            if (data.success) {
              setSnackbarMessage({ msg: "Details added successfully", success: false });
              setSnackbarOpen(true);
              navigate('/hospital-information')
            }
          }
          if (location.state.from === 'feedback') {

            const { data } = await addFeedback(feedbackResponse)

            if (data.success) {
              setSnackbarMessage({ msg: "Feedback added successfully", success: true });
              setSnackbarOpen(true);
              setOpen(true)
            }
          }
          if (location.state.from === 'services') {

            const { data } = await addServices(services)

            if (data.success) {
              setSnackbarMessage({ msg: "Request raised successfully", success: true });
              setSnackbarOpen(true);
              setOpen(true)
            }
          }
        }
        catch (err) {
          console.log(err);
          setSnackbarMessage({ msg: err.message, success: false });
          setSnackbarOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false)
      })
  }

  // sx={{backgroundColor:'#454'}}
  return (
    <Box sx={{ width: '100vw', height: '100vh' }}>
      <ResponsiveAppBar />
      <Grid display='flex' alignItems='center' flexDirection='column' height={{xs:'76%',sm:'76%'}} >
        
        <Grid container  display='flex' justifyContent='center' alignItems='center' flexDirection='column' rowSpacing={2} mt={4}>
          <Typography sx={{ fontWeight: 600, fontSize: '20px', m: 2 }}>OTP Authentication</Typography>
          {viewOtpForm ? (
            <Box component="form" noValidate autoComplete="off" onSubmit={handleOtp} mt={2}>
              <Stack display='flex' justifyContent='center' alignItems='center' flexDirection='column' spacing={3}>
                <Typography>Enter OTP</Typography>
                <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false} />
                <Box display='flex' justifyContent='space-between' alignItems='center' mt={2}>
                  {/* <Button variant='contained' >Back</Button> */}
                  <Button variant='contained' type='submit'>{isLoading ? <CircularProgress /> : 'Submit'}</Button>
                </Box>
              </Stack>
            </Box>
          ) : (
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} mt={2}>
              <Stack display='flex' justifyContent='center' alignItems='center' flexDirection='column' spacing={3}>
                <TextField
                  label="Name"
                  variant="outlined"
                  name='name'
                  type='text'
                  value={inputs.name}
                  onChange={handleChange}
                />
                <TextField
                  label="Phone"
                  variant="outlined"
                  name='phone'
                  type='tel'
                  value={inputs.phone}
                  onChange={handleChange}
                />
                <div id="recaptcha-container"></div>
                <Box display='flex' justifyContent='space-between' alignItems='center' width='230px'>
                  <Button variant='contained' >Back</Button>
                  <Button variant='contained' type='submit'>{isLoading ? <CircularProgress /> : 'Submit'}</Button>
                </Box>
              </Stack>
            </Box>)}
          
        </Grid>
      </Grid>


      <Dialog open={open} onClose={handleClose} display='flex' justifyContent='center' alignItems='center'>
        {location.state.from === 'services' &&

          <DialogContent >
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
              <Box><img src={subTic} alt='img' width='80px' height='80px' /></Box>
              <Typography sx={{ fontWeight: 600, fontSize: '24px', mt: 1 }}>Thank you very much!</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '16px' }} align='center'>Your request has been  forwarded to the concerned department and will be attended to shortly.</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '16px', mt: 3 }} align='center'>Any further requests, kindly let us know.</Typography>
              <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>We will be happy to help.</Typography>
              <Button variant='contained' onClick={handleClose} sx={{ mt: 2 }}>Continue</Button>
            </Box>
          </DialogContent>
        } 
        {location.state.from === 'feedback' &&

          <DialogContent >
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
              <Box><img src={subTic} alt='img' width='80px' height='80px' /></Box>
              <Typography sx={{ fontWeight: 600, fontSize: '24px', mt: 1 }}>Thank you very much!</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '16px' }} align='center'>Your feedback has been saved and help us improve our services even more closely to your desires and needs.</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '16px', mt: 3 }} align='center'>Would you like us to call you regarding your feedback?</Typography>
              <Box sx={{ display: "flex", justifyContent: 'space-between', margin: 3, width: '200px' }}>
                <Button id='Yes' onClick={handleCallback} variant="contained" color="success">
                  <Typography fontFamily="Monument Extended">Yes!</Typography>
                </Button>
                <Button id='No' onClick={handleCallback} variant="contained" color="error">
                  <Typography fontFamily="Monument Extended">No!</Typography>
                </Button>
              </Box>
            </Box>
          </DialogContent>
        }
      </Dialog>
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />


      <AppFooter />
    </Box>
  );
}
