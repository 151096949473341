//mui
import { Box, Typography, styled } from "@mui/material";
import { useState } from "react";
//png
import MAP from '../../assets/map.png'
import ImageDialog from "../../pages/imageDialog";


const StyledImage = styled('img')(() => ({
    height: "80%",
    width: "80%",
    margin: "20px 0",
    borderRadius: "5px"
}))


export default function Map() {
    const [imageDialogOpen, setImageDialogOpen] = useState(false)
    return (
        <>
        <Box id='campus' sx={{ width: { xs: "90%", md: "75%", lg: "60%" }, margin: "20px auto", textAlign: "center", cursor:'pointer' }} onClick = {() => setImageDialogOpen(true)
}>
            <Typography variant='h4'>Campus</Typography>
            <StyledImage src={MAP} />

        </Box>
        <ImageDialog dialogOpen={imageDialogOpen} setDialogOpen = {setImageDialogOpen} imageFile={MAP}/>
        </>
    )
}
