import { styled, Typography, Box, Button } from "@mui/material";
import FeedbackTextbox from "../FeedbackTextbox/FeedbackTextbox";

const Card = styled("div")(() => ({
  boxSizing: "border-box",
  minWidth: "600px",
  minHeight: "450px",
  borderRadius: "12px",
  background: "rgb(252, 253, 255)",
  // boxShadow: "10px -10px 20px #dbdce4,-10px 10px 20px #ffffff",
  textAlign: "center",
  cursor: "pointer",
  transition: "all 0.5s",
  display: "flex",
  flexDirection: "column",
  // alignItems: "center",
  // justifyContent: "center",
  userSelect: "none",
  fontWeight: "bolder",
  margin: "20px",
  "&:hover": {
    border: "1px solid #57C192",
    transform: "scale(1.05)",
  },
  "@media screen and (max-width:600px)": {
    minWidth: "350px",
    minHeight: "450px",
  },
}));

const List = styled("ul")(() => ({
  listStyle: "none",
  margin: 0,
  padding: 0,
}));

const ListItem = styled("li")(() => ({
  background: "#57C192",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px",
  width: "80%",
  margin: "10px auto",
  border: "1px solid #ccc",
  borderRadius: " 5px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease",
  "&hover": {
    borderColor: "#666",
    transform: "translateY(-2px)",
  },
}));

export default function ServicesChecklist({
  type,
  services,
  onCheckboxChange,
}) {
  return (
    <Card>
      <Typography
        variant="h5"
        p={"10px 0"}
        style={{ color: "#57C192", fontWeight: "Bold" }}
      >
        {services[0].title}
      </Typography>
      <List>
        {services.map((item, index) => {
          return (
            <ListItem>
              <label htmlFor="id">{item.service}</label>
              <input
                type="checkbox"
                id={item.id}
                style={{ marginRight: "10px" }}
                checked={item.selected}
                onChange={(e) => onCheckboxChange(item.id, e.target.checked)}
              />

              {/* <input type="checkbox" id="id" style={{ marginRight: "10px" }} /> */}
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
}
