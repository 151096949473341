import illustration1 from '../assets/illustrations/illustration_login.png'
import illustration2 from '../assets/illustrations/illustration_avatar.png'


const EventsData = [
    {
        image: illustration1,
        title: "Lorem Ipsum",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, sit    voluptates nihil modi iste magnam illum consequuntur praesentium ducimus et nam, impedit commodi at eaque rem delectus doloremque recusandae architecto odit perspiciatis consequatur? Delectus, libero. Earum harum libero quibusdam atque?"
    },
    {
        image: illustration2,
        title: "Lorem Ipsum",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, sit    voluptates nihil modi iste magnam illum consequuntur praesentium ducimus et nam, impedit commodi at eaque rem delectus doloremque recusandae architecto odit perspiciatis consequatur? Delectus, libero. Earum harum libero quibusdam atque?"
    },
    {
        image: illustration1,
        title: "Lorem Ipsum",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, sit    voluptates nihil modi iste magnam illum consequuntur praesentium ducimus et nam, impedit commodi at eaque rem delectus doloremque recusandae architecto odit perspiciatis consequatur? Delectus, libero. Earum harum libero quibusdam atque?"
    },
    {
        image: illustration2,
        title: "Lorem Ipsum",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, sit    voluptates nihil modi iste magnam illum consequuntur praesentium ducimus et nam, impedit commodi at eaque rem delectus doloremque recusandae architecto odit perspiciatis consequatur? Delectus, libero. Earum harum libero quibusdam atque?"
    },
];

export default EventsData;