import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
// components
import Logo from "../../components/logo";
import { Typography } from "@mui/material";

// ----------------------------------------------------------------------

const StyledHeader = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  // lineHeight: 0,
  margin:"0 auto",
  width: "99%",
  position: "absolute",
  paddingTop: "5px",
  paddingLeft: "5px",
  textAlign: "center",
}));
const StyledFooter = styled("footer")(() => ({
  width:"99%",
  textAlign:'center'
}));

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  return (
    <>
      <StyledHeader>
        <Logo />
      </StyledHeader>
      <Outlet />
      <StyledFooter>
        <Typography align="center" variant="subtitle">
          Powered by : Tellercomm
        </Typography>
      </StyledFooter>
    </>
  );
}
