import { createApiConfig } from "./config";
import { GET_FEEDBACK_QUESTION_URL, GET_HOSPITAL_URL, GET_SERVICES_URL, POST_SERVICES_URL, POST_FEEDBACK_QUESTION_URL, GET_HOSPITAL_INFO_URL, POST_CONTACT_URL} from "./routes";


const apiConfig = createApiConfig();



/**
 * @desc  Login User API Service
 * @param  {string} email
 * @param  {string} password
 */
export const getHospitalDetails = (path) => {
  return apiConfig.get(`${GET_HOSPITAL_URL}/${path}` );
};

export const getHospitalInfo = (id) => {
  return apiConfig.get(`${GET_HOSPITAL_INFO_URL}/${id}` );
};

export const getFeedbackQuestion = (id) => {
  return apiConfig.get(`${GET_FEEDBACK_QUESTION_URL}/${id}` );
};

export const postFeedbackQuestion = (inputs) => {
  return apiConfig.post(POST_FEEDBACK_QUESTION_URL, inputs);
};
export const postContact = (inputs, id) => {
  return apiConfig.post(`${POST_CONTACT_URL}/${id}`, inputs);
};
export const getServices = (id) => {
  return apiConfig.get(`${GET_SERVICES_URL}/${id}` );
};

export const addServices = (inputs) => {
  return apiConfig.post(POST_SERVICES_URL, inputs);
};

export const addFeedback = (inputs) => {
  return apiConfig.post(POST_FEEDBACK_QUESTION_URL, inputs);
};