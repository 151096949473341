import AboutHospital from "../components/AboutHospital/AboutHospital";
import HospitalLanding from "../components/HospitalLanding/HospitalLanding";
import { styled } from "@mui/material";
import Carousel from "../components/Carousel/Carousel";
import servicesData from "../data/servicesData";
import Doctors from "../components/Doctor's directory/Doctors";
import Footer from "../components/Footer/Footer";
import Contact from "../components/Contactus/Contact";
import Map from "../components/Map/Map";
import EventsCarousel from "../components/EventCarousel/EventCarousel";
import EventsData from "../data/EventsData";
import CustomNavbar from "../components/navbar/Navbar";
import AppFooter from "../components/BottomNavigation";
import ResponsiveAppBar from "../components/Appbar";
import { useContext, useEffect, useState } from "react";
import { getHospitalInfo } from "../api/auth";
import CustomSnackBar from "../components/snackbar";
import { QrContext } from "../provider";


export default function Hospitalinfo() {
  const { qrDetails } = useContext(QrContext);

  const [hospitalInfo, setHospitalInfo] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  const fetchData = async () => {

    try {
      const { data } = await getHospitalInfo(qrDetails.hospitalId)
      if (data.success) {

        setHospitalInfo(data.hospitalInformation)
        // console.log(data);

      }
      else {
        setSnackbarMessage({ msg: "Request failed", success: false });
        setSnackbarOpen(true);
      }

    }
    catch (error) {
      // setIsLoading(false)
      console.log("err ", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <>
      {/* <CustomNavbar hospitalInfo={true} /> */}
      {/* <Navbar hospitalInfo={true} /> */}
      <ResponsiveAppBar />
      {hospitalInfo &&
        <>
          <HospitalLanding hospitalName = {hospitalInfo.hospitalName}/>
          <AboutHospital aboutUs = {hospitalInfo.aboutUs}/>
          <Carousel  specialities = {hospitalInfo.servicesAndFacilities.split(',')} />
          <Doctors doctors = {hospitalInfo.doctorsDirectory.split(',')}/>
          <Map />
          <EventsCarousel data={EventsData} />
          <Contact />
        </>
      }
      <AppFooter />
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />

    </>
  );
}
