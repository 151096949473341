import { Navbar, Nav, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../logo/Logo";

export default function CustomNavbar({ hospitalInfo }) {
  return (
    <Navbar bg="light" expand="md" fixed="top">
      <Container fluid>
        <Navbar.Brand className="ms-5 p-0" href="/">
          <Logo />
        </Navbar.Brand>
        {hospitalInfo && <>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav className="ms-auto">
              <Nav.Link href="#aboutus">About Us</Nav.Link>
              <Nav.Link href="#services">Specialities </Nav.Link>
              <Nav.Link href="#doctors">Doctors</Nav.Link>
              <Nav.Link href="#campus">Campus</Nav.Link>
              <Nav.Link href="#contact">Contact Us</Nav.Link>
              {/* <Nav.Link href="#reachus">Reach Us</Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </>}

      </Container>
    </Navbar>
  );
}
