import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

//mui
import { Box, Button, styled, Typography } from "@mui/material";
import FeedbackQuestion from "../FeedbackQuestion/FeedbackQuestion";
import FeedbackTextbox from "../FeedbackTextbox/FeedbackTextbox";
import Questions from "../../data/Feedbackquestion";

const Wrapper = styled("div")(() => ({
  background: "#E9EBFF",
  minHeight: "100vh",
}));

function FeedbackResults() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categories = searchParams.get("categories") || "";
  const categoryList = categories.split(",");
  console.log(Questions);
  console.log(categoryList);
  const [questions, setQuestions] = useState(() => {
    if (categoryList[0] === "") {
      return Questions.filter((ques) => ques.type === "general");
    } else {
      return Questions.filter(
        (ques) => categoryList.includes(ques.type) || ques.type === "general"
      );
    }
  });

  const [comment, setComment] = useState();
  const handleFeedbackChange = (e) => {
    const { value, dataset } = e.target;
    const { questionId, feedbackType } = dataset;
    const newQuestions = questions.map((question) => {
      if (question.id === parseInt(questionId)) {
        return {
          ...question,
          feedback: {
            ...question.feedback,
            [feedbackType]: value,
          },
        };
      } else {
        return question;
      }
    });
    setQuestions(newQuestions);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(questions, comment);
    navigate(`/authentication`);
  };

  return (
    <Wrapper>
      <Typography variant="h4" align="center">
        Give your feedback
      </Typography>
      {/* {categories === "" ? ( */}

      <form onSubmit={handleSubmit}>
        {questions.map((question) => (
          <FeedbackQuestion
            key={question.id}
            question={question}
            handleFeedbackChange={handleFeedbackChange}
          />
        ))}
        <Typography
          align="left"
          sx={{ marginLeft: { md: "10%" }, padding: "5px" }}
        >
          What are the things you feel we should improve upon, and do you have
          any other recommendations or concerns?
        </Typography>
        <FeedbackTextbox
          comment={comment}
          setComment={setComment}
        ></FeedbackTextbox>
        <Box sx={{ width: "100%", display: "flex", padding: "20px 0" }}>
          <Button
            type="submit"
            variant="contained"
            color="success"
            sx={{ margin: "0 auto" }}
          >
            Submit
          </Button>
        </Box>
      </form>

      {/* ) : (
        <form onSubmit={handleSubmit}>
          {categoryList.map((category, index) => (
            <li id={index}>{category}</li>
          ))}
        </form>
      )} */}
    </Wrapper>
  );
}

export default FeedbackResults;
