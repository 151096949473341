import React, { useRef, useState } from "react";
import { Box, TextField, Typography, Button, styled } from "@mui/material";
import { AiOutlineSend } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const FormContainer = styled("div")(() => ({
  width: "80%",
  margin: "50px auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: { xs: "column-reverse", md: "row" },
  position: "relative",
  left: "2%",
}));


const ContactUs = () => {
  const navigate = useNavigate()
  const [values, setValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(values);
    navigate('/authentication', { state: { requestedService:values, from:'hosinfo' } })
  }

  const form = useRef();
  return (
    <>
      <Typography variant="h4" sx={{ textAlign: "center" }} id="contact">
        Contact Us
      </Typography>
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TextField
              sx={{
                borderRadius: "20px",
                width: { xs: "100%", md: "45%" },
              }}
              id="name"
              name="name"
              onChange={handleChange}
              value={values?.name}
              label="Name"
              variant="outlined"
              required
            ></TextField>
            <TextField
              sx={{
                borderRadius: "20px",
                mt: { xs: "5%", md: "0" },
                width: { xs: "100%", md: "45%" },
              }}
              id="email"
              onChange={handleChange}
              value={values?.email}
              name="email"
              label="Email"
              variant="outlined"
              required
            ></TextField>
          </Box>
          <Box>
            <TextField
              id="subject"
              onChange={handleChange}
              value={values?.subject}
              sx={{
                borderRadius: "20px",
                mt: "5%",
                width: "100%",
              }}
              name="subject"
              label="Subject"
              variant="outlined"
              required
            ></TextField>
          </Box>
          <Box>
            <TextField
              multiline
              rows={8}
              sx={{
                borderRadius: "20px",
                mt: "5%",
                width: "100%",
              }}
              id="message"
              name="message"
              label="Message"
              variant="outlined"
              onChange={handleChange}
              required
            ></TextField>
          </Box>

          <Button
            type="submit"
            endIcon={<AiOutlineSend />}
            variant="contained"
            sx={{
              backgroundColor: "#57C192",
              mt: "10%",
              padding: "3%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              // position: "relative",
              // left: { xs: "10%", sm: "25%" },
            }}
          >
            Send Message
          </Button>
        </form>
      </FormContainer>
    </>
  );
};
export default ContactUs;
