import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, styled, Typography } from "@mui/material";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import EventsBanner from "../EventBanner/EventBanner";

// const CarouselContainer = styled("div")(() => ({
//     margin: "50px auto",
//     maxWidth: "80vw",
//   }))
export default function EventsCarousel({ data }) {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: "linear",
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        arrows: false
    };

    return (
        <Box sx={{ margin: "50px auto" }} id="Events">
            <Typography variant="h4" align="center" sx={{ marginBottom: "20px" }}>
                What's New
            </Typography>
            <Slider {...settings}>
                {data.map((item, index) => {
                    return (
                        <EventsBanner key={index} title={item.title} description={item.description} image={item.image} />
                    )
                })}
            </Slider>
        </Box>
    );
}
